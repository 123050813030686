(function webpackUniversalModuleDefinition(root, factory) {
	if(typeof exports === 'object' && typeof module === 'object')
		module.exports = factory(require("./jquery.svg"), require("./jquery.svgfilter"), require("jquery"));
	else if(typeof define === 'function' && define.amd)
		define(["./jquery.svg", "./jquery.svgfilter", "jquery"], factory);
	else if(typeof exports === 'object')
		exports["WMFJS"] = factory(require("./jquery.svg"), require("./jquery.svgfilter"), require("jquery"));
	else
		root["WMFJS"] = factory(root["$"], root["$"], root["$"]);
})(this, function(__WEBPACK_EXTERNAL_MODULE__1__, __WEBPACK_EXTERNAL_MODULE__2__, __WEBPACK_EXTERNAL_MODULE__4__) {
return 